<template>
  <vx-card>
    <div class="vx-col sm:w-1/1 w-full mb-base">
      <vs-tabs>
        <vs-tab label="BILLORDER Report">
          <div class="tab-text">
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Operationg Unit / Territory</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                  class="selectExample"
                  v-model="selectedTerritories"
                  :options="optionTerritories"
                  :multiple="true"
                  :allow-empty="true"
                  :group-select="false"
                  :max-height="120"
                  :limit="3"
                  placeholder="Type to search"
                  :custom-label="customLabel"
                  track-by="id"
                >
                </multiselect>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Distribution Channel</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                  class="selectExample"
                  v-model="selectedDistributionChannel"
                  :options="optionDistributionChannel"
                  :multiple="true"
                  :allow-empty="true"
                  :group-select="false"
                  :max-height="120"
                  :limit="3"
                  placeholder="Type to search"
                  :custom-label="customLabelWithNoCode"
                  track-by="id"
                >
                </multiselect>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Sales</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                  class="selectExample"
                  v-model="selectedSales"
                  :options="optionSales"
                  :multiple="true"
                  :allow-empty="true"
                  :group-select="false"
                  :max-height="120"
                  :limit="3"
                  placeholder="Type to search"
                  :custom-label="customLabelWithNoCode"
                  track-by="id"
                >
                </multiselect>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Division / Principal</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                  class="selectExample"
                  v-model="selectedPricipal"
                  :options="optionPricipal"
                  :multiple="true"
                  :allow-empty="true"
                  :group-select="false"
                  :max-height="120"
                  :limit="3"
                  placeholder="Type to search"
                  :custom-label="customLabelWithNoCode"
                  track-by="id"
                >
                </multiselect>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>SO Date</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <date-range-picker
                  ref="picker"
                  opens="center"
                  :singleDatePicker="false"
                  :timePicker="false"
                  :timePicker24Hour="false"
                  :showWeekNumbers="true"
                  :showDropdowns="true"
                  :autoApply="true"
                  v-model="soDate"
                  class="w-full"
                >
                  <template v-slot:input="picker" style="width: 100%">
                    {{ dateFormat(picker.startDate) }} -
                    {{ dateFormat(picker.endDate) }}
                  </template>
                </date-range-picker>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Posting Date</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <date-range-picker
                  ref="picker"
                  opens="center"
                  :singleDatePicker="false"
                  :timePicker="false"
                  :timePicker24Hour="false"
                  :showWeekNumbers="true"
                  :showDropdowns="true"
                  :autoApply="true"
                  v-model="soDate"
                  class="w-full"
                >
                  <template v-slot:input="picker" style="width: 100%">
                    {{ dateFormat(picker.startDate) }} -
                    {{ dateFormat(picker.endDate) }}
                  </template>
                </date-range-picker>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Billing Date</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <date-range-picker
                  ref="picker"
                  opens="center"
                  :singleDatePicker="false"
                  :timePicker="false"
                  :timePicker24Hour="false"
                  :showWeekNumbers="true"
                  :showDropdowns="true"
                  :autoApply="true"
                  v-model="billingDate"
                  class="w-full"
                >
                  <template v-slot:input="picker" style="width: 100%">
                    {{ dateFormat(picker.startDate) }} -
                    {{ dateFormat(picker.endDate) }}
                  </template>
                </date-range-picker>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-button color="success" @click="handleDrawTable()"
                  >Get Data</vs-button
                >
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col md:w-1/1 w-full mb-base">
                <BillOrderTable
                  :territoryIDs="this.territoryIDs"
                  :ouIDs="this.ouIDs"
                  :distributionChannelIDs="this.distributionChannelIDs"
                  :principalIDs="this.principalIDs"
                  :salesIDs="this.salesIDs"
                  :startSoDate="this.soDate.startDate"
                  :endSoDate="this.soDate.endDate"
                  :startPostingDate="this.postingDate.startDate"
                  :endPostingDate="this.postingDate.endDate"
                  :startBillingDate="this.billingDate.startDate"
                  :endBillingDate="this.billingDate.endDate"
                  :draw="this.draw"
                />
              </div>
            </div>
          </div>
        </vs-tab>
        <vs-tab label="Export">
          <div class="tab-text">
            <data-table-export></data-table-export>
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
  </vx-card>
</template>
<script>
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import BillOrderTable from './BillOrderTable.vue';
import DataTableExport from './DataTableExport.vue';
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';
import { mapState, mapActions } from 'vuex/dist/vuex.common.js';

export default {
  components: {
    BillOrderTable,
    DataTableExport,
    DateRangePicker,
    Datepicker,
  },
  data() {
    return {
      baseUrl: '/api/report/v1/territories',
      selectedTerritories: [{id: 0, name: 'All', code:'-'}],
      optionTerritories: [{id: 0, name: 'All', code:'-'}],
      selectedDistributionChannel: [{id: 0, name: 'All'}],
      optionDistributionChannel: [{id: 0, name: 'All'}],
      selectedSales: [{id: 0, name: 'All'}],
      optionSales: [{id: 0, name: 'All'}],
      selectedPricipal: [{id: 0, name: 'All'}],
      optionPricipal: [{id: 0, name: 'All'}],
      territoryIDs: [],
      distributionChannelIDs: [],
      salesIDs: [],
      principalIDs: [],
      territoryCodes: [],
      territoryNames: [],
      ouIDs: [],
      draw: 0,
      soDate: {
        startDate: new Date(),
        endDate: new Date(),
      },
      postingDate: {
        startDate: new Date(),
        endDate: new Date(),
      },
      billingDate: {
        startDate: new Date(),
        endDate: new Date(),
      },
    };
  },
  methods: {
    ...mapActions({
      getOu: 'reportBillOrder/getOu',
      getDistributionChannel: 'reportBillOrder/getDistributionChannel',
      getSales: 'reportBillOrder/getSales',
      getPrincipal: 'reportBillOrder/getPrincipal',
    }),
    customLabel(val) {
      if (val) {
        return val.code === '-' ? `${val.name}` : `(${val.code}) ${val.name}`;
      }
    },
    customLabelWithNoCode(val) {
      if (val) {
        return val.name;
      }
    },
    handleClose() {
      this.detail = false;
    },

    handleOpen() {
      this.detail = true;
    },
    dateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format('dddd, MMMM Do YYYY');
      }
      return a;
    },
    getTerritories() {
      this.$http.get('/api/report/v1/master/territory').then((resp) => {
        this.optionTerritories = [...this.optionTerritories, ...resp.data.records];
      });
    },
    handleDrawTable() {
      this.draw++;
    },
  },
  mounted() {
    this.getTerritories();
    this.getOu();
    this.getDistributionChannel();
    this.getSales();
    this.getPrincipal();
    this.draw++;
  },
  watch: {
    selectedTerritories(val) {
      let territoryIDs = [];
      let territoryCodes = [];
      let territoryNames = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          territoryIDs.push(e.id);
          territoryCodes.push(e.code);
          territoryNames.push(e.name);
        }
      });

      this.territoryIDs = territoryIDs;
      this.territoryCodes = territoryCodes;
      this.territoryNames = territoryNames;
    },
    selectedOu(val) {
      let ouIDs = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          ouIDs.push(e.id);
        }
      });

      this.ouIDs = ouIDs;
    },
    selectedDistributionChannel(val) {
      let distributionChannelIDs = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          distributionChannelIDs.push(e.id);
        }
      });

      this.distributionChannelIDs = distributionChannelIDs;
    },
    selectedSales(val) {
      let salesIDs = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          salesIDs.push(e.id);
        }
      });

      this.salesIDs = salesIDs;
    },
    selectedPricipal(val) {
      let principalIDs = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          principalIDs.push(e.id);
        }
      });

      this.principalIDs = principalIDs;
    },

    ouList(val) {
      this.optionOu = val;
    },
    distributionChannelList(val) {
      this.optionDistributionChannel = [...this.optionDistributionChannel, ...val];
    },
    salesList(val) {
      this.optionSales = [...this.optionSales, ...val];
    },
    principalList(val) {
      this.optionPricipal = [...this.optionPricipal, ...val];
    },
  },
  computed: {
    ...mapState({
      ouList: (state) => state.reportBillOrder.ouList,
      distributionChannelList: (state) => state.reportBillOrder.distributionChannelList,
      salesList: (state) => state.reportBillOrder.salesList,
      principalList: (state) => state.reportBillOrder.principalList,
    }),
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}

.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
.core-leave-to

/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
