<template>
  <div>
    <vs-table search stripe border description :sst="true" :data="listData" :max-items="table.length"
      :total="table.total" @search="handleSearch" @change-page="handleChangePage" @sort="handleSort">
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div class="
                p-3
                border border-solid
                d-theme-border-grey-light
                rounded-full
                d-theme-dark-bg
                cursor-pointer
                flex
                items-center
                justify-between
                font-medium
              ">
            <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
        <div class="btn-group ml-5">
          <vs-button size="small" color="success" type="border" @click="handleExport('excel')">Excel</vs-button>
        </div>
      </template>
      <template slot="thead">
        <!-- <vs-th></vs-th> -->
        <vs-th sort-key="sales_office">Sales Office</vs-th>
        <vs-th sort-key="sales_office_desc">Sales Office Desc</vs-th>
        <vs-th sort-key="dist_channel">Dist Channel</vs-th>
        <vs-th sort-key="div_code">Div Code</vs-th>
        <vs-th sort-key="div_desc">Div Desc</vs-th>
        <vs-th sort-key="sold_to_party">Sold to Party</vs-th>
        <vs-th sort-key="sold_to_party_name">Sold to Party Name</vs-th>
        <vs-th sort-key="material_code">Material Code</vs-th>
        <vs-th sort-key="material_desc">Material Desc</vs-th>
        <vs-th sort-key="product_category">Product Category</vs-th>
        <vs-th sort-key="po_number">PO Number</vs-th>
        <vs-th sort-key="so_number">SO Number</vs-th>
        <vs-th sort-key="so_date">SO Date</vs-th>
        <vs-th sort-key="do_number">DO Number</vs-th>
        <vs-th sort-key="gi_number">GI Number</vs-th>
        <vs-th sort-key="billing_number">Billing Number</vs-th>
        <vs-th sort-key="billing_date">Billing Date</vs-th>
        <vs-th sort-key="billing_posting_date">Billing Posting Date</vs-th>
        <vs-th sort-key="actual_gi_date">Actual GI Date</vs-th>
        <vs-th sort-key="billing_type">Billing Type</vs-th>
        <vs-th sort-key="order_type">Order Type</vs-th>
        <vs-th sort-key="order_type_desc">Order Type Desc</vs-th>
        <vs-th sort-key="sales_code">Sales Code</vs-th>
        <vs-th sort-key="sales_desc">Sales Desc</vs-th>
        <vs-th sort-key="bill_net_value">Bill Net Value</vs-th>
        <vs-th sort-key="doc_status">Doc Status</vs-th>
        <vs-th sort-key="cancel_reason">Cancel Reason</vs-th>
        <vs-th sort-key="cogs">COGS</vs-th>
        <vs-th sort-key="bill_gross_value">Bill Gross Value</vs-th>
        <vs-th sort-key="gross_margin">Gross Margin</vs-th>
        <vs-th sort-key="gross_margin_percentage">Gross Margin Percentage</vs-th>
        <vs-th sort-key="bill_qty">Bill Qty</vs-th>
        <vs-th sort-key="bill_unit">Bill Unit</vs-th>
        <vs-th sort-key="bill_qty_lvl_3">Bill Qty Level 3</vs-th>
        <vs-th sort-key="bill_unit_lvl_3">Bill Unit Level 3</vs-th>
        <vs-th sort-key="bill_weight">Bill Weight</vs-th>
        <vs-th sort-key="bill_weight_unit">Bill Weight Unit</vs-th>
        <vs-th sort-key="bill_item">Bill Item</vs-th>
        <vs-th sort-key="currency">Currency</vs-th>
        <vs-th sort-key="discount_principal">Discount Principal</vs-th>
        <vs-th sort-key="discount_dist">Discount Dist</vs-th>
        <vs-th sort-key="top_code">TOP Code</vs-th>
        <vs-th sort-key="top_name">TOP Name</vs-th>
        <vs-th sort-key="cust_grp">Cust Grp</vs-th>
        <vs-th sort-key="cust_grp_desc">Cust Grp Desc</vs-th>
        <vs-th sort-key="cust_grp_1">Cust Grp 1</vs-th>
        <vs-th sort-key="cust_grp_1_desc">Cust Grp Desc 1</vs-th>
        <vs-th sort-key="cust_grp_2">Cust Grp 2</vs-th>
        <vs-th sort-key="cust_grp_2_desc">Cust Grp Desc 2</vs-th>
        <vs-th sort-key="cust_grp_3">Cust Grp 3</vs-th>
        <vs-th sort-key="cust_grp_3_desc">Cust Grp Desc 3</vs-th>
        <vs-th sort-key="cust_grp_4">Cust Grp 4</vs-th>
        <vs-th sort-key="cust_grp_4_desc">Cust Grp Desc 4</vs-th>
        <vs-th sort-key="posting_type">Posting Type</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <!-- <vs-td> </vs-td> -->
          <vs-td :data="data[indextr].sales_office">
            {{ data[indextr].sales_office }}
          </vs-td>
          <vs-td :data="data[indextr].sales_office_desc">
            {{ data[indextr].sales_office_desc }}
          </vs-td>
          <vs-td :data="data[indextr].dist_channel">
            {{ data[indextr].dist_channel }}
          </vs-td>
          <vs-td :data="data[indextr].div_code">
            {{ data[indextr].div_code }}
          </vs-td>
          <vs-td :data="data[indextr].div_desc">
            {{ data[indextr].div_desc }}
          </vs-td>
          <vs-td :data="data[indextr].sold_to_party">
            {{ data[indextr].sold_to_party }}
          </vs-td>
          <vs-td :data="data[indextr].sold_to_party_name">
            {{ data[indextr].sold_to_party_name }}
          </vs-td>
          <vs-td :data="data[indextr].material_code">
            {{ data[indextr].material_code }}
          </vs-td>
          <vs-td :data="data[indextr].material_desc">
            {{ data[indextr].material_desc }}
          </vs-td>
          <vs-td :data="data[indextr].product_category">
            {{ data[indextr].product_category }}
          </vs-td>
          <vs-td :data="data[indextr].po_number">
            {{ data[indextr].po_number }}
          </vs-td>
          <vs-td :data="data[indextr].so_number">
            {{ data[indextr].so_number }}
          </vs-td>
          <vs-td :data="data[indextr].so_date">
            {{ dateFormat(data[indextr].so_date) }}
          </vs-td>
          <vs-td :data="data[indextr].do_number">
            {{ data[indextr].do_number }}
          </vs-td>
          <vs-td :data="data[indextr].gi_number">
            {{ data[indextr].gi_number }}
          </vs-td>
          <vs-td :data="data[indextr].billing_number">
            {{ data[indextr].billing_number }}
          </vs-td>
          <vs-td :data="data[indextr].billing_date">
            {{ dateFormat(data[indextr].billing_date) }}
          </vs-td>
          <vs-td :data="data[indextr].billing_posting_date">
            {{ dateFormat(data[indextr].billing_posting_date) }}
          </vs-td>
          <vs-td :data="data[indextr].actual_gi_date">
            {{ dateFormat(data[indextr].actual_gi_date) }}
          </vs-td>
          <vs-td :data="data[indextr].billing_type">
            {{ data[indextr].billing_type }}
          </vs-td>
          <vs-td :data="data[indextr].order_type">
            {{ data[indextr].order_type }}
          </vs-td>
          <vs-td :data="data[indextr].order_type_desc">
            {{ data[indextr].order_type_desc }}
          </vs-td>
          <vs-td :data="data[indextr].sales_code">
            {{ data[indextr].sales_code }}
          </vs-td>
          <vs-td :data="data[indextr].sales_desc">
            {{ data[indextr].sales_desc }}
          </vs-td>
          <vs-td :data="data[indextr].bill_net_value">
            {{ data[indextr].bill_net_value }}
          </vs-td>
          <vs-td :data="data[indextr].doc_status">
            {{ data[indextr].doc_status }}
          </vs-td>
          <vs-td :data="data[indextr].cancel_reason">
            {{ data[indextr].cancel_reason }}
          </vs-td>
          <vs-td :data="data[indextr].cogs">
            {{ data[indextr].cogs }}
          </vs-td>
          <vs-td :data="data[indextr].bill_gross_value">
            {{ data[indextr].bill_gross_value }}
          </vs-td>
          <vs-td :data="data[indextr].gross_margin">
            {{ data[indextr].gross_margin }}
          </vs-td>
          <vs-td :data="data[indextr].gross_margin_percentage">
            {{ data[indextr].gross_margin_percentage }}
          </vs-td>
          <vs-td :data="data[indextr].bill_qty">
            {{ data[indextr].bill_qty }}
          </vs-td>
          <vs-td :data="data[indextr].bill_unit">
            {{ data[indextr].bill_unit }}
          </vs-td>
          <vs-td :data="data[indextr].bill_qty_lvl_3">
            {{ data[indextr].bill_qty_lvl_3 }}
          </vs-td>
          <vs-td :data="data[indextr].bill_unit_lvl_3">
            {{ data[indextr].bill_unit_lvl_3 }}
          </vs-td>
          <vs-td :data="data[indextr].bill_weight">
            {{ data[indextr].bill_weight }}
          </vs-td>
          <vs-td :data="data[indextr].bill_weight_unit">
            {{ data[indextr].bill_weight_unit }}
          </vs-td>
          <vs-td :data="data[indextr].bill_item">
            {{ data[indextr].bill_item }}
          </vs-td>
          <vs-td :data="data[indextr].currency">
            {{ data[indextr].currency }}
          </vs-td>
          <vs-td :data="data[indextr].discount_principal">
            {{ data[indextr].discount_principal }}
          </vs-td>
          <vs-td :data="data[indextr].discount_dist">
            {{ data[indextr].discount_dist }}
          </vs-td>
          <vs-td :data="data[indextr].top_code">
            {{ data[indextr].top_code }}
          </vs-td>
          <vs-td :data="data[indextr].top_name">
            {{ data[indextr].top_name }}
          </vs-td>
          <vs-td :data="data[indextr].cust_grp">
            {{ data[indextr].cust_grp }}
          </vs-td>
          <vs-td :data="data[indextr].cust_grp_desc">
            {{ data[indextr].cust_grp_desc }}
          </vs-td>
          <vs-td :data="data[indextr].cust_grp_1">
            {{ data[indextr].cust_grp_1 }}
          </vs-td>
          <vs-td :data="data[indextr].cust_grp_1_desc">
            {{ data[indextr].cust_grp_1_desc }}
          </vs-td>
          <vs-td :data="data[indextr].cust_grp_2">
            {{ data[indextr].cust_grp_2 }}
          </vs-td>
          <vs-td :data="data[indextr].cust_grp_2_desc">
            {{ data[indextr].cust_grp_2_desc }}
          </vs-td>
          <vs-td :data="data[indextr].cust_grp_3">
            {{ data[indextr].cust_grp_3 }}
          </vs-td>
          <vs-td :data="data[indextr].cust_grp_3_desc">
            {{ data[indextr].cust_grp_3_desc }}
          </vs-td>
          <vs-td :data="data[indextr].cust_grp_4">
            {{ data[indextr].cust_grp_4 }}
          </vs-td>
          <vs-td :data="data[indextr].cust_grp_4_desc">
            {{ data[indextr].cust_grp_4_desc }}
          </vs-td>
          <vs-td :data="data[indextr].posting_type">
            {{ data[indextr].posting_type }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination style="padding-top: 5px" :total="totalPage" v-model="setPage" />
  </div>
</template>
<script>
import moment from "moment";
import { mapState, mapActions } from "vuex/dist/vuex.common.js";
export default {
  components: {},
  props: {
    startSoDate: {
      type: Date,
    },
    endSoDate: {
      type: Date,
    },
    startPostingDate: {
      type: Date,
    },
    endPostingDate: {
      type: Date,
    },
    startBillingDate: {
      type: Date,
    },
    endBillingDate: {
      type: Date,
    },
    territoryIDs: {
      type: Array,
    },
    ouIDs: {
      type: Array,
    },
    distributionChannelIDs: {
      type: Array,
    },
    salesIDs: {
      type: Array,
    },
    principalIDs: {
      type: Array,
    },
    draw: {
      type: Number,
    },
  },
  data() {
    return {
      deleteId: null,
      table: this.tableDefaultState(),
    };
  },
  methods: {
    ...mapActions({ 
      getBillOrderReport: 'reportBillOrder/getBillOrderReport',
      generateBillOrderReport: 'reportBillOrder/generateBillOrderReport',
    }),
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleExport() {
      this.$vs.loading();
      this.generateBillOrderReport({
          length: this.table.length,
          page: this.table.page,
          search: this.table.search,
          order: this.table.order,
          sort: this.table.sort,
          ou: this.ouIDs.length === 0 ? undefined : this.ouIDs.join(','),
          territory: this.territoryIDs.length === 0 ? undefined : this.territoryIDs.join(','),
          distribution_channel: this.distributionChannelIDs.length === 0 ? undefined : this.distributionChannelIDs.join(','),
          sales: this.salesIDs.length === 0 ? undefined : this.salesIDs.join(','),
          division_principal: this.principalIDs.length === 0 ? undefined : this.principalIDs.join(','),
          start_so_date: this.startSoDate == null
            ? undefined
            : moment(this.startSoDate).format('YYYY-MM-DD'),
          end_so_date: this.endSoDate == null
            ? undefined
            : moment(this.endSoDate).format('YYYY-MM-DD'),
          start_posting_date: this.startPostingDate == null
            ? undefined
            : moment(this.startPostingDate).format('YYYY-MM-DD'),
          end_posting_date: this.endPostingDate == null
            ? undefined
            : moment(this.endPostingDate).format('YYYY-MM-DD'),
          start_billing_date: this.startBillingDate == null
            ? undefined
            : moment(this.startBillingDate).format('YYYY-MM-DD'),
          end_billing_date: this.endBillingDate == null
            ? undefined
            : moment(this.endBillingDate).format('YYYY-MM-DD')
      }).then(() => {
                    this.$vs.loading.close();
      }).catch(() => {
                    this.$vs.loading.close();
      })
            
    },
    getData() {
      if (this.draw > 0) {
        this.$vs.loading();
        this.getBillOrderReport({
          length: this.table.length,
          page: this.table.page,
          search: this.table.search,
          order: this.table.order,
          sort: this.table.sort,
          ou: this.ouIDs.length === 0 ? undefined : this.ouIDs.join(','),
          territory: this.territoryIDs.length === 0 ? undefined : this.territoryIDs.join(','),
          distribution_channel: this.distributionChannelIDs.length === 0 ? undefined : this.distributionChannelIDs.join(','),
          sales: this.salesIDs.length === 0 ? undefined : this.salesIDs.join(','),
          division_principal: this.principalIDs.length === 0 ? undefined : this.principalIDs.join(','),
          start_so_date: this.startSoDate == null
            ? undefined
            : moment(this.startSoDate).format('YYYY-MM-DD'),
          end_so_date: this.endSoDate == null
            ? undefined
            : moment(this.endSoDate).format('YYYY-MM-DD'),
          start_posting_date: this.startPostingDate == null
            ? undefined
            : moment(this.startPostingDate).format('YYYY-MM-DD'),
          end_posting_date: this.endPostingDate == null
            ? undefined
            : moment(this.endPostingDate).format('YYYY-MM-DD'),
          start_billing_date: this.startBillingDate == null
            ? undefined
            : moment(this.startBillingDate).format('YYYY-MM-DD'),
          end_billing_date: this.endBillingDate == null
            ? undefined
            : moment(this.endBillingDate).format('YYYY-MM-DD')
        })
          .then(() => {
            this.$vs.loading.close();
          });
      }
    },
    dateFormat(val) {
      return moment(val).format("DD MMM YYYY");
    },
    formatPrice(val) {
      const formatter = new Intl.NumberFormat('id-ID', {style:'currency', currency:'IDR'})
      return formatter.format(val)
    },
    format(head, value) {
      if (typeof head["format"] === "function") {
        var f = head["format"];
        return f(value);
      }
      return value;
    },
  },
  watch: {
    draw() {
      this.getData();
    },
    dataList(val) {
        this.table.start = ((this.table.page - 1) * this.table.length) + 1
        this.table.end = ((this.table.page - 1) * this.table.length) + val.length
        },
        generateReport(val) {
      if (val && val.status === 'OK') {
        this.$vs.notify({
          color: "success",
          title: "Processing",
          text: val.message,
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      } else {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: val.message,
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }
    },
    totalRecord(val) {
      this.table.total = val
    },
  },
  computed: {
    ...mapState({
      listData: (state) => state.reportBillOrder.tableData,
      totalPage: (state) => state.reportBillOrder.total,
      generateReport: (state) => state.reportBillOrder.generateReport,
      totalRecord: (state) => state.reportBillOrder.total_record,
    }),
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>